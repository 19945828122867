import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import CloseIcon from '@mui/icons-material/Close';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Box, DialogContent, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import SizedImage from '../../../common/components/SizedImage';
import FileUtils from '../../../common/utils/file.utils';
import BrandBox from '../../../common/components/BrandBox';
import { BuyerInfoViewProps } from './BuyerInfoDialog';
import ContractStatusTag from '../../../search/ContractStatusTag';
import { getGMapUrlForAddress } from '../../../common/utils/common.utils';

export default function BuyerInfoGeneralView({ company, close, changeView }: BuyerInfoViewProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts']);
  const companyAddress = company.addresses[0];
  const photo = company.photos && company.photos[0];

  return (
    <DialogContent sx={{ maxWidth: 500, height: 620, p: 0 }}>
      <Box sx={{ display: 'flex' }}>
        <SizedImage
          src={FileUtils.BuildCompanyImageUrl({
            photo: photo?.photo,
            type: Business_Profile_Enum.Buyer,
            size: { height: 200, width: 500 },
          })}
          alt={t('common:company')}
          height={200}
          width={500}
          borderRadius={0}
          sx={{ flexShrink: 1 }}
          overlay={(
            <>
              <IconButton
                onClick={close}
                sx={{
                  position: 'absolute',
                  top: 16,
                  right: 16,
                  zIndex: 100,
                  color: 'text.primary',
                  backgroundColor: 'white',
                  '&:hover': {
                    backgroundColor: 'white',
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              {!!company.brand && (
                <Box sx={{ position: 'absolute', bottom: 16, left: 16, zIndex: 100 }}>
                  <BrandBox brand={company.brand} height={72} width={72} logoWidth={48} />
                </Box>
              )}
            </>
          )}
        />
      </Box>
      <Box sx={{ px: 2, py: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h5">
          <ContractStatusTag
            status={Contract_Status_Enum.Signed}
            size={24}
            sx={{ verticalAlign: 'middle', mr: 1 }}
          />
          {company.tradeName}
        </Typography>
        <List
          sx={{
            '& > *:not(:last-child)': {
              borderBottom: '1px solid',
              borderColor: 'divider',
            },
          }}
        >
          <ListItemButton onClick={() => changeView('CONTACTS')}>
            <ListItemIcon sx={{ minWidth: 48 }}>
              <StorefrontIcon sx={{ color: 'text.primary' }} />
            </ListItemIcon>
            <ListItemText
              primary={t('contracts:company_contacts')}
              primaryTypographyProps={{
                sx: {
                  lineHeight: '2rem',
                },
              }}
            />
            <ArrowRightOutlinedIcon />
          </ListItemButton>
          <ListItemButton onClick={() => changeView('DELIVERY')}>
            <ListItemIcon sx={{ minWidth: 48 }}>
              <LocalShippingOutlinedIcon sx={{ color: 'text.primary' }} />
            </ListItemIcon>
            <ListItemText
              primary={t('common:delivery')}
              primaryTypographyProps={{
                sx: {
                  lineHeight: '2rem',
                },
              }}
            />
            <ArrowRightOutlinedIcon />
          </ListItemButton>
          <ListItemButton href={getGMapUrlForAddress(companyAddress.formattedAddress)} target="_blank">
            <ListItemText
              primary={(
                <>
                  📍&nbsp;
                  <Typography sx={{ textDecoration: 'underline', display: 'inline' }}>
                    {companyAddress.formattedAddress}
                  </Typography>
                </>
              )}
              primaryTypographyProps={{
                sx: {
                  lineHeight: '2rem',
                },
              }}
            />
            <OpenInNewOutlinedIcon />
          </ListItemButton>
          <ListItem>
            <ListItemText
              primary={<>📞 {company.contactPhoneNumber}</>}
              primaryTypographyProps={{
                sx: {
                  lineHeight: '2rem',
                },
              }}
            />
          </ListItem>
        </List>
      </Box>
    </DialogContent>
  );
}
