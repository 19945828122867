import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, IconButton, OutlinedInput, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import { Measurement_Unit_Enum, Order_Status_Enum } from 'kheops-graphql';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import i18n from '../../i18n';
import { editedOrderItemsAtom } from '../../orders/state/state';
import { round } from '../utils/common.utils';
import VersionedValue from './VersionedValue';
import useOrderItem from '../../hooks/useOrderItem';

interface ItemEditBoxProps {
  packagingId: string;
  canEditWeight?: boolean;
  shouldEditBeEnabledByDefault?: boolean;
  orderStatus: Order_Status_Enum;
  orderId?: string;
}

export default function PackagingWeightEditBox({ packagingId, canEditWeight, orderStatus, orderId, shouldEditBeEnabledByDefault = false }: ItemEditBoxProps): React.JSX.Element {
  const { weight, quantity, defaultPackagingWeight, previousWeight, defaultWeight } = useOrderItem(packagingId) || {};
  const [displayedValue, setDisplayedValue] = useState<number | null | undefined>(weight);
  const editItem = useSetAtom(editedOrderItemsAtom);
  const numberFormat = new Intl.NumberFormat(i18n.resolvedLanguage, {
    maximumFractionDigits: 3,
    minimumFractionDigits: 0,
  });
  const [isInEditMode, setIsInEditMode] = useState(shouldEditBeEnabledByDefault);
  const [shouldDisplayCancelButton, setShouldDisplayCancelButton] = useState(false);

  const formatUnit = (unit: Measurement_Unit_Enum): string => {
    return unit === Measurement_Unit_Enum.L ? unit : unit.toLocaleLowerCase();
  };
  const valueFormatter = (value: number | bigint): string => {
    return `${numberFormat.format(value)} ${defaultPackagingWeight?.unit ? formatUnit(defaultPackagingWeight.unit) : ''}`;
  };

  const handleChange = (event: ChangeEvent): void => {
    const input = event.target as HTMLInputElement;
    const newWeight = input.valueAsNumber;
    if (Number.isNaN(newWeight)) {
      setDisplayedValue(undefined);
      return;
    }
    editItem(
      {
        id: packagingId,
        quantity: quantity || 0,
        adjustedWeight: newWeight,
      },
    );
    setShouldDisplayCancelButton(newWeight !== defaultWeight);
    setDisplayedValue(newWeight);
  };

  useEffect(() => {
    // update displayed value whenever order status is updated, to avoid having undefined as value after submitting order.
    setDisplayedValue(weight);
    setIsInEditMode(shouldEditBeEnabledByDefault);
  }, [orderStatus, orderId]);

  useEffect(() => {
    // update displayed balue whenever weight is updated from outside this component.
    setDisplayedValue(weight);
  }, [weight]);

  const handleOnCancelClick = (): void => {
    editItem();
    setIsInEditMode(shouldEditBeEnabledByDefault);
    setShouldDisplayCancelButton(false);
    setDisplayedValue(defaultWeight);
  };

  const totalWeight = useMemo(() => {
    if (defaultPackagingWeight && quantity !== undefined) {
      return weight || round(defaultPackagingWeight.value * quantity);
    }
  }, [defaultPackagingWeight, quantity, weight]);

  useEffect(() => {
    setDisplayedValue(totalWeight);
  }, [totalWeight]);

  const handleEditButton = (): void => {
    setIsInEditMode(true);
    setShouldDisplayCancelButton(true);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isInEditMode ? (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
        >
          {totalWeight !== undefined && defaultPackagingWeight && (
            <VersionedValue
              currentValue={totalWeight}
              previousValue={defaultWeight !== totalWeight ? defaultWeight : previousWeight}
              valueFormatter={valueFormatter}
              shouldHighlightDifference
              color="text.main"
              variant="body1"
            />
          )}
          {canEditWeight && (
            <IconButton onClick={handleEditButton} color="secondary">
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <OutlinedInput
            endAdornment={<Typography variant="body2" fontWeight={700}>{formatUnit(defaultPackagingWeight!.unit)}</Typography>}
            sx={{
              maxWidth: 110,
              height: 32,
              '& input[type=number]::-webkit-outer-spin-button': {
                display: 'none',
              },
              '& input[type=number]::-webkit-inner-spin-button': {
                display: 'none',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: weight !== defaultWeight ? '#F3A42E !important' : undefined,
              },
            }}
            value={displayedValue}
            onChange={handleChange}
            type="number"
            inputProps={{
              step: '0.001',
            }}
          />
          {shouldDisplayCancelButton && (
            <IconButton size="small" onClick={handleOnCancelClick}>
              <CloseIcon sx={{ width: 16 }} />
            </IconButton>
          )}
        </Box>
      )}
    </>
  );
}
