import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { deliveryContactsAtom, invoicingContactsAtom } from '../../../state';
import { UserRoleDocument } from '../../../queries/__generated__/userRole.generated';
import { useUpdateCompanyContactsMutation } from '../../../mutations/__generated__/updateCompanyContacts.generated';
import { commonSnackbarPropsAtom } from '../../../common/state/state';
import useOpenable from '../../../hooks/useOpenable';
import ContactsForm from './ContactsForm';
import ContactList from './ContactList';
import FormDrawer from '../FormDrawer';

export type ContactsType = 'DELIVERY' | 'INVOICING';

export interface ContactsInfoProps {
  type: ContactsType;
}

const TypeContextData = {
  DELIVERY: {
    contactsAtom: deliveryContactsAtom,
    updateContactKey: 'is_delivery_contact',
    newContactUpdatedTrans: 'settings:delivery_contacts_successfully_updated',
    newContactAddedTrans: 'settings:delivery_contacts_successfully_added',
    addContactTrans: 'settings:add_delivery_contact',
    addContactTransDescription: 'settings:add_delivery_contact_description',
  },
  INVOICING: {
    contactsAtom: invoicingContactsAtom,
    updateContactKey: 'is_invoicing_contact',
    newContactUpdatedTrans: 'settings:invoicing_contacts_successfully_updated',
    newContactAddedTrans: 'settings:invoicing_contacts_successfully_added',
    addContactTrans: 'settings:add_invoicing_contact',
    addContactTransDescription: 'settings:add_invoicing_contact_description',
  },
};

export default function ContactsInfo({ type }: ContactsInfoProps): React.JSX.Element {
  const { contactsAtom, updateContactKey, newContactUpdatedTrans, newContactAddedTrans, addContactTrans, addContactTransDescription } = TypeContextData[type];
  const { t } = useTranslation(['common', 'settings']);
  const contacts = useAtomValue(contactsAtom);
  const { open, close, isOpen } = useOpenable();
  const [updateCompanyContacts, { data: upsertCompanyContactsData }] = useUpdateCompanyContactsMutation({
    refetchQueries: [UserRoleDocument],
  });
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);

  const onSubmit = async (newContactIds: string[]): Promise<void> => {
    const formerContactIds = contacts.reduce((acc, { id }) => {
      if (!newContactIds.includes(id)) {
        acc.push(id);
      }

      return acc;
    }, [] as string[]);

    updateCompanyContacts({
      variables: {
        formerContactIds,
        formerContactExp: {
          [updateContactKey]: false,
        },
        newContactIds,
        newContactExp: {
          [updateContactKey]: true,
        },
      },
    });
  };

  useEffect((): void => {
    if (upsertCompanyContactsData?.new_contacts_update || upsertCompanyContactsData?.former_contacts_update) {
      setCommonSnackbarProps({
        label: t(contacts.length ? newContactUpdatedTrans : newContactAddedTrans),
        snackbarProps: {
          open: true,
        },
      });
      close();
    }
  }, [upsertCompanyContactsData]);

  return (
    <>
      <Box
        sx={{
          '& .MuiTypography-root': {
            lineHeight: 'normal',
          },
        }}
      >
        {
          contacts.length
            ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <ContactList contacts={contacts} />
                <Button
                  variant="outlined"
                  onClick={open}
                  startIcon={<EditOutlinedIcon />}
                  sx={{ alignSelf: 'start' }}
                >
                  {t('common:modify')}
                </Button>
              </Box>
            )
            : (
              <>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {t('settings:no_contact_registered')}
                </Typography>
                <Typography variant="body2">
                  {t(addContactTransDescription)}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon fontSize="small" />}
                  sx={{ mt: 2 }}
                  onClick={open}
                >
                  {t('common:add')}
                </Button>
              </>
            )
        }
      </Box>
      <FormDrawer
        title={t(addContactTrans)}
        open={isOpen}
        onClose={close}
        FormComponent={ContactsForm}
        formProps={{
          contacts,
          description: addContactTransDescription,
          onSubmit,
        }}
      />
    </>
  );
}
