import { ThemeProvider } from '@mui/system';
import { frFR } from '@mui/material/locale';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { renderTimeViewClock } from '@mui/x-date-pickers-pro';
import React from 'react';

declare module '@mui/material/styles' {
  interface Palette {
    marketing1: Palette['primary'];
    marketing2: Palette['primary'];
    marketing3: Palette['primary'];
    ghost: Palette['primary'];
    greys: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    marketing2?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    extraSmall: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    extraLarge: true;
  }

  interface ButtonPropsColorOverrides {
    marketing1: true;
    marketing2: true;
    ghost: true
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    marketing1: true;
    accent: true;
  }

  interface FabPropsSizeOverrides {
    extraLarge: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    marketing1: true;
    marketing2: true;
    marketing3: true;
    layout: true;
    discount: true;
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    accent: true;
  }
}

interface CustomThemeProviderProps {
  children: React.ReactNode;
}
export default function CustomThemeProvider({ children }: CustomThemeProviderProps): React.JSX.Element {
  const clearface = "'Clearface', sans-serif";
  const ambit = "'Ambit', sans-serif";
  const colors = {
    black: '#102D2D',
    grey: '#535D5F',
    darkSnow: '#D6D6D6',
    mediumSnow: '#ECEDEE',
    snow: '#F6F7F8',
    primary: '#00B86E',
    gold: '#FFD28F',
    goldLight: '#FEE27A',
    error: '#E7444F',
    schrimp: '#FFC4D4',
    schrimpLight: '#FFDECC',
    tomato: '#FF7051',
    success: '#00B649',
    cream: '#FCF2E1',
  };
  const coreTheme = {
    palette: {
      common: { black: colors.black },
      text: {
        primary: colors.black,
        secondary: colors.grey,
        disabled: colors.darkSnow,
      },
      divider: colors.darkSnow,
      primary: {
        light: '#00B6861A',
        active: colors.gold,
        main: colors.primary,
        dark: '#399370',
        contrastText: '#fff',
      },
      secondary: {
        light: colors.snow,
        main: colors.black,
        dark: '#8b2500',
        contrastText: '#ffffff',
      },
      error: {
        main: colors.error,
      },
      warning: {
        main: '#F3A42E',
        light: '#F3A42E1A',
      },
      marketing1: {
        main: colors.gold,
        dark: '#F5CE94',
        contrastText: colors.black,
      },
      marketing2: {
        main: colors.schrimpLight,
        dark: '#F5D4C2',
        contrastText: colors.black,
      },
      marketing3: {
        main: colors.schrimp,
        contrastText: colors.black,
      },
      accent: {
        main: colors.tomato,
        contrastText: colors.black,
      },
      discount: {
        main: colors.goldLight,
        contrastText: colors.black,
      },
      layout: {
        main: colors.cream,
        contrastText: colors.black,
      },
      ghost: {
        main: '#ffffff',
        dark: colors.snow,
        contrastText: colors.black,
      },
      success: {
        main: colors.success,
      },
      greys: {
        main: colors.grey,
        primary: colors.snow,
        light: colors.mediumSnow,
        dark: colors.darkSnow,
        contrastText: colors.black,
      },
    },
  } as ThemeOptions;

  const componentsTheme = {
    typography: {
      fontFamily: ambit,
      h1: {
        fontFamily: clearface,
        fontSize: '2rem',
      },
      h2: {
        fontFamily: clearface,
        fontSize: '1.75rem',
      },
      h3: {
        fontFamily: clearface,
        fontSize: '1.5rem',
      },
      h4: {
        fontFamily: clearface,
        fontSize: '1.5rem',
      },
      h5: {
        fontFamily: ambit,
        fontSize: '1.25rem',
        fontWeight: 600,
      },
      h6: {
        fontFamily: ambit,
        fontSize: '1.125rem',
        fontWeight: 600,
      },
      subtitle1: {
        fontFamily: ambit,
        fontSize: '0.875rem',
        color: colors.black,
        fontWeight: 400,
      },
      subtitle2: {
        fontFamily: ambit,
        fontSize: '0.875rem',
        color: colors.grey,
        fontWeight: 400,
      },
      body1: {
        fontFamily: ambit,
        color: colors.black,
        lineHeight: '20px',
        fontSize: '1rem',
        fontWeight: 400,
      },
      body2: {
        fontFamily: ambit,
        color: colors.grey,
        lineHeight: '20px',
        fontSize: '1rem',
        fontWeight: 400,
      },
      title: {
        fontFamily: ambit,
        color: colors.black,
        fontSize: '1.5rem',
        fontWeight: 800,
      },
    },
    palette: {
      background: {
        default: colors.cream,
      },
    },
    components: {
      MuiDialog: {
        styleOverrides: {
          paperFullScreen: {
            borderRadius: 0,
          },
          paper: {
            borderRadius: '24px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: '500',
            fontSize: '1rem',
            borderRadius: '100px',
            textTransform: 'none',
            whiteSpace: 'nowrap',
            '&:hover': { boxShadow: 'none' },
          },
          sizeSmall: {
            fontSize: '0.875rem',
            padding: '0.5rem 1rem',
          },
          contained: { boxShadow: 'none' },
          outlinedPrimary: {
            color: colors.primary,
          },
        },
        variants: [
          {
            props: { size: 'extraLarge' },
            style: ({ theme }) => ({
              padding: `${theme.spacing(1.5)} ${theme.spacing(8)}`,
            }),
          },
        ],
      },
      MuiIconButton: {
        variants: [
          {
            props: { size: 'extraSmall' },
            style: () => ({
              fontSize: '1.125rem',
            }),
          },
        ],
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
        variants: [
          {
            props: { size: 'extraLarge' },
            style: {
              width: 72,
              height: 72,
              '& .MuiSvgIcon-root': {
                fontSize: 28,
              },
            },
          },
        ],
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.black,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused.MuiInputLabel-outlined': {
              color: colors.black,
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderWidth: 0,
            // eslint-disable-next-line max-len
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within':
              { outlineWidth: '0px' },
            '& .MuiDataGrid-columnHeaderTitleContainer': { paddingLeft: '0px' },
            '& .MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end' },
            '& .MuiDataGrid-columnsContainer': { borderBottomColor: 'transparent' },
          },
          cell: {
            color: colors.black,
            borderBottomColor: colors.darkSnow,
          },
          row: {
            cursor: 'pointer',
          },
          columnHeader: {
            color: colors.grey,
            fontSize: '0.75rem',
            '.MuiDataGrid-columnHeaderTitle': { fontWeight: 300 },
            '.MuiBadge-anchorOriginTopRightRectangular': {
              display: 'none',
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '1rem',
            '&.Mui-selected': {
              fontWeight: 700,
            },
          },
          labelIcon: {
            minHeight: 0,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 24,
            backgroundColor: 'background.paper',
            boxShadow: 'none',
            padding: 24,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            textAlign: 'center',
            marginTop: '1.5rem',
            fontSize: '1.5rem',
            fontFamily: clearface,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            paddingBottom: '1.5rem',
            paddingRight: '1.5rem',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paperAnchorBottom: {
            height: 'calc(100% - 88px)',
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            boxShadow: 'none',
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 28,
            height: 16,
            marginRight: 4,
            padding: 0,
            display: 'flex',
            '&:active': {
              '& .MuiSwitch-thumb': {
                width: 15,
              },
              '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
              },
            },
            '& .MuiSwitch-switchBase': {
              padding: 2,
              '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                  opacity: 1,
                  backgroundColor: colors.primary,
                },
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
              width: 12,
              height: 12,
              borderRadius: 6,
            },
            '& .MuiSwitch-track': {
              borderRadius: 16 / 2,
              opacity: 1,
              backgroundColor: colors.darkSnow,
              boxSizing: 'border-box',
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: '0.5rem',
          },
          sizeSmall: {
            fontSize: '0.875rem',
            '& .MuiChip-icon': {
              color: colors.black,
              fontSize: '1rem',
              height: 16,
              width: 16,
            },
          },
          sizeMedium: {
            fontSize: '1rem',
            fontWeight: 600,
            '& .MuiChip-icon': {
              color: colors.black,
              fontSize: '1.25rem',
              height: 18,
              width: 18,
            },
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            '& .MuiAlert-root': {
              borderRadius: '8px',
            },
          },
        },
      },
      MuiAlert: {
        variants: [
          {
            props: { severity: 'success' },
            style: {
              color: 'white',
            },
          },
        ],
      },
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
      MuiDesktopDateTimePicker: {
        defaultProps: {
          viewRenderers: {
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          },
        },
      },
    },
  } as ThemeOptions;

  const theme = createTheme(componentsTheme, coreTheme, frFR);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
