import { useAtomValue } from 'jotai';
import { currentContextAtom } from '../../../../state';
import { DeliveryHoursDraft, WeekDays } from '../../state';
import { convertSqlTimeToDate } from '../../../../common/utils/date.utils';

export default function useDeliveryHoursFormDefaultValues(): Partial<DeliveryHoursDraft> {
  const { deliveryHours, deliveryDescription } = useAtomValue(currentContextAtom);
  const hasDeliveryHours = !!deliveryHours?.length;

  return {
    weekDays: (deliveryHours || []).map(({ week_day }) => week_day as WeekDays),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openingHour: hasDeliveryHours ? convertSqlTimeToDate(deliveryHours[0].opening_hour) : '' as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    closingHour: hasDeliveryHours ? convertSqlTimeToDate(deliveryHours[0].closing_hour) : '' as any,
    deliveryDescription,
  };
}
