import { Box, Button, FormHelperText, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Maybe } from 'kheops-graphql';
import { BaseFormProps } from '../FormDrawer';
import CountryAutocomplete from '../../../common/components/CountryAutocomplete';
import { Address } from './Address';

export interface AddressDraft {
  address: Address,
  additionalInfo?: string;
  phoneNumber?: string;
  email?: string;
}

export interface AddressFormProps extends BaseFormProps {
  onSubmit: (values: AddressDraft) => void;
  address: Address;
  additionalInfo?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  email?: Maybe<string>;
  additionalInfoLabel?: string;
}

export default function AddressForm({ address, additionalInfo, phoneNumber, email, additionalInfoLabel, onSubmit, onChange, onCancel }: AddressFormProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'settings', 'validation']);
  const { control, formState: { isDirty }, handleSubmit, watch } = useForm<AddressDraft>({
    defaultValues: {
      address,
      additionalInfo: additionalInfo || '',
      phoneNumber: phoneNumber || '',
      email: email || '',
    },
  });
  const values = watch();

  useEffect((): void => {
    onChange(isDirty);
  }, [values, isDirty]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        '& .MuiInputBase-root:not(.MuiInputBase-multiline)': { height: 48 },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="subtitle2">
          {t('common:company_name')}*
        </Typography>
        <Controller
          name="address.name"
          control={control}
          rules={{
            required: t('validation:this_field_is_required') as string,
          }}
          render={({ field, fieldState }) => (
            <>
              <TextField
                {...field}
                fullWidth
                error={!!fieldState.error}
                placeholder={t('common:company_name')}
              />
              {fieldState.error
                && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
            </>
          )}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="subtitle2">
          {t('common:country')}*
        </Typography>
        <Controller
          name="address.country"
          control={control}
          rules={{
            required: t('validation:this_field_is_required') as string,
          }}
          render={({ field, fieldState }) => (
            <>
              <CountryAutocomplete value={field.value} onChange={field.onChange} />
              {fieldState.error
                && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
            </>
          )}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="subtitle2">
          {t('common:address')}*
        </Typography>
        <Controller
          name="address.address_1"
          control={control}
          rules={{
            required: t('validation:this_field_is_required') as string,
          }}
          render={({ field, fieldState }) => (
            <>
              <TextField
                {...field}
                fullWidth
                placeholder={t('settings:address_line_1')}
                error={!!fieldState.error}
              />
              {fieldState.error
                && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
            </>
          )}
        />
        <Controller
          name="address.address_2"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              placeholder={t('settings:address_line_2')}
              error={!!fieldState.error}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1 }}>
          <Typography variant="subtitle2">
            {t('common:postal_code')}*
          </Typography>
          <Controller
            name="address.postal_code"
            control={control}
            rules={{
              required: t('validation:this_field_is_required') as string,
            }}
            render={({ field, fieldState }) => (
              <>
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  placeholder={t('common:postal_code')}
                />
                {fieldState.error
                  && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1 }}>
          <Typography variant="subtitle2">
            {t('common:city')}*
          </Typography>
          <Controller
            name="address.city"
            control={control}
            rules={{
              required: t('validation:this_field_is_required') as string,
            }}
            render={({ field, fieldState }) => (
              <>
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  placeholder={t('common:city')}
                />
                {fieldState.error
                  && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="subtitle2">
          {t(additionalInfoLabel || 'settings:additional_info')}
        </Typography>
        <Controller
          name="additionalInfo"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              multiline
              minRows={2}
              placeholder={t(additionalInfoLabel || 'settings:additional_info')}
              {...field}
            />
          )}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="subtitle2">
          {t('common:phone_number')}
        </Typography>
        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            validate: (number) => (!number || isValidPhoneNumber(number, { defaultCountry: 'FR' }) || t('settings:invalid_number')),
          }}
          render={({ field, fieldState }) => (
            <>
              <TextField
                {...field}
                fullWidth
                placeholder={t('common:phone_number')}
                error={!!fieldState.error}
              />
              {fieldState.error
                && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
            </>
          )}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="subtitle2">
          {t('common:email_address')}
        </Typography>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <TextField
                {...field}
                type="email"
                placeholder={t('common:email_address')}
                fullWidth
                error={!!fieldState.error}
              />
              {fieldState.error
                && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
            </>
          )}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, mt: 1 }}>
        <Button variant="text" color="secondary" onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!isDirty}>
          {t('common:validate')}
        </Button>
      </Box>
    </Box>
  );
}
